import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { Subject, takeUntil } from 'rxjs';
import { SidebarComponent } from 'src/app/layouts/main-layout/components/sidebar/sidebar.component';
import { DetailTicketModel } from 'src/app/models/detail-ticket.model';
import { TicketService } from 'src/app/services/api/ticket.service';
import { ToastService } from 'src/app/services/toast.service';

@Component({
  selector: 'app-detail-ticket',
  templateUrl: './detail-ticket.component.html',
  styleUrls: ['./detail-ticket.component.scss'],
})
export class DetailTicketComponent {
  private destroy$ = new Subject<boolean>();
  isCollapsed = false;
  id!: string;
  ticket_number: string = '';
  awb: string = '';
  shipping: string = '';
  description: string = '';
  seller: string = '';
  customer: string = '';
  type_ticket: string = '';
  admin: string = '';
  status_ticket: string = '';
  order_status: string = '';
  attachments: any;
  detailTicket!: DetailTicketModel;
  confirmModal?: NzModalRef;

  constructor(
    private toastService: ToastService,
    private route: Router,
    private activeRoute: ActivatedRoute,
    private ticketService: TicketService,
    private modal: NzModalService
  ) {}
  ngOnInit(): void {
    this.activeRoute.params.subscribe((params) => {
      this.id = String(params['id']);
    });
    this.getDetailTicket();
  }

  modifyAttachmentURL(v: string): string {
    if (v && v.includes('/attachment/prod/')) {
      return v.replace('/attachment/prod/', '');
    } else if (v && v.includes('/attachment/dev/')) {
      return v.replace('/attachment/dev/', '');
    }
    return v;
  }

  back() {
    this.route.navigate(['ticketing']);
  }

  detailOrder(id: number) {
    this.route.navigate([`data-order/detail-order/${id}`]);
  }

  showConfirm(): void {
    this.confirmModal = this.modal.confirm({
      nzTitle: `<div class="d-flex flex-column justify-content-center align-items-center mb-2">
      <div class="d-flex flex-column justify-content-center align-items-center" style="width: 500px;">
        <div class="overflow-hidden d-flex align-items-center justify-content-center">
          <img height="100px" src="https://storage.googleapis.com/komerce/core/icon-popup-warning.png" alt="">
        </div>
      </div>
  </div>`,
      nzContent: 'Anda Yakin Ingin Membatalkan Tiket?',
      nzOkDanger: true,
      nzZIndex: 1000,
      nzCancelText: 'Kembali',
      nzClassName: 'rounded',
      nzIconType: '',
      nzCentered: true,
      nzOnOk: () =>
        new Promise((resolve, reject) => {
          setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
          this.cancelTicket();
        }).catch(() => console.log('Oops errors!')),
    });
  }
  cancelTicket() {
    this.ticketService
      .cancelTicket(this.id)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (res) => {
          this.toastService.show(null, 'Tiket Berhasil Dibatalkan', 'success', {
            classname: 'bg-success',
            delay: 3000,
          });
          window.location.reload();
        },
        () => {}
      );
  }
  whatsApp(no: string) {
    if (no === '') {
      return;
    } else {
      let whatsappNumber = no;
      if (whatsappNumber.startsWith('62')) {
        whatsappNumber = whatsappNumber.substring(2);
      }

      window.open(`https://wa.me/${whatsappNumber}`, '_blank');
    }
  }
  salinTicket(): void {
    const ticket = this.ticket_number;
    navigator.clipboard
      .writeText(ticket)
      .then(() => {
        this.toastService.show(
          null,
          'Nomor Tiket berhasil disalin',
          'success',
          {
            classname: 'bg-success',
            delay: 3000,
          }
        );
      })
      .catch((e) => console.log(e));
  }
  salinAWB(): void {
    const ticket = this.awb;
    navigator.clipboard
      .writeText(ticket)
      .then(() => {
        this.toastService.show(null, 'Nomor Resi berhasil disalin', 'success', {
          classname: 'bg-success',
          delay: 3000,
        });
      })
      .catch((e) => console.log(e));
  }
  getDetailTicket(): void {
    this.ticketService
      .detailTicket(this.id)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (res) => {
          this.detailTicket = res.data;
          this.awb = res.data.awb;
          this.ticket_number = res.data.ticket_no;
          this.seller = res.data.seller;
          this.shipping = res.data.shipping;
          this.description = res.data.description;
          this.status_ticket = res.data.status_ticket;
          this.type_ticket = res.data.type_ticket;
          this.order_status = res.data.order_status;
          this.attachments = res.data.attachments;
          this.admin = res.data.brand;
          this.customer = res.data.customer;
        },
        () => {}
      );
  }

  classStatus(value: string) {
    let result = '';
    if (value === 'Selesai') {
      result = 'succes';
    } else if (value === 'Sedang Diproses') {
      result = 'process';
    } else if (value === 'Belum Diproses') {
      result = 'fu';
    } else if (value === 'Perlu Tindakan Lanjut') {
      result = 'fu';
    }
    return result;
  }
  classOrder(value: string) {
    let result = '';
    if (value === 'Retur') {
      result = 'retur';
    } else if (value === 'Dikirim') {
      result = 'diterima';
    } else if (value === 'Diterima') {
      result = 'diterima';
    } else if (value === 'Sedang Diproses') {
      result = 'dipacking';
    } else if (value === 'Dipacking') {
      result = 'dipacking';
    } else if (value === 'Perlu Tindakan Lanjut') {
      result = 'retur';
    } else if (value === 'Batal') {
      result = 'retur';
    }
    return result;
  }
  ngOnDestroy(): void {
    this.destroy$.unsubscribe();
  }
}
