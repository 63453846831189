import {
  Component,
  OnDestroy,
  OnInit,
  EventEmitter,
  Input,
  NgModule,
  Output,
  LOCALE_ID,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { Router, RouterModule, Routes, ActivatedRoute } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { FormGroup, FormsModule } from '@angular/forms';
import { BalanceService } from 'src/app/services/api/balance.service';
import { ToastService } from 'src/app/services/toast.service';
import * as moment from 'moment';
import 'moment/locale/id';
import {
  LocaleConfig,
  DaterangepickerDirective,
} from 'ngx-daterangepicker-material';
import { NgbDateParserFormatter, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Dayjs } from 'dayjs';
import { offset } from '@popperjs/core';
import { HistoryBalanceModel } from 'src/app/models/history-balance.model';
import { BalanceResponseType } from 'src/app/types/response/balance-response.type';
import { ResponseCustomType } from 'src/app/types/response/response.type';
import { balanceModel } from 'src/app/models/balance.model';
import { ModalDlRincianSaldo } from 'src/app/modules/shared/components/modal-download-rincian-saldo/modal-download-rincian-saldo.component';

@Component({
  selector: 'app-detail-saldo',
  templateUrl: './detail-saldo.component.html',
  styleUrls: ['./detail-saldo.component.scss'],
  providers: [{ provide: LOCALE_ID, useValue: 'id' }],
})
export class DetailSaldoComponent {
  @Input() HistoryList: HistoryBalanceModel[] = [];
  @Input() filterHistory: any = {
    start_date: moment().subtract(6, 'days').format('YYYY-MM-DD'),
    end_date: moment().format('YYYY-MM-DD'),
    transaction_type:
      'top_up,withdrawal,order_shipping,order_returned,order_received,order_cancel,insurance_fee',
    limit: 50,
    offset: 0,
    airway_bill: '',
  };
  keepCalendarOpeningWithRange: boolean;
  @Output() filterResult = new EventEmitter();
  destroy$ = new Subject();
  @ViewChild(DaterangepickerDirective, { static: false })
  pickerDirective!: DaterangepickerDirective;
  @ViewChild('checkBoxRef', { static: false }) checkBoxRef!: ElementRef;
  @Input() balance!: balanceModel;
  form!: FormGroup;
  uncheck = false;
  uncheckList!: boolean;
  private searchSubject = new Subject<string>();

  selected: any = {
    startDate: moment().subtract(6, 'days'),
    endDate: moment(),
  };

  ranges: any = {
    'Hari ini': [moment(), moment()],
    Kemarin: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
    '7 hari terakhir': [moment().subtract(6, 'days'), moment()],
    '30 hari terakhir': [moment().subtract(29, 'days'), moment()],
    'Bulan ini': [moment().startOf('month'), moment().endOf('month')],
    'Bulan Lalu': [
      moment().subtract(1, 'month').startOf('month'),
      moment().subtract(1, 'month').endOf('month'),
    ],
  };

  invalidDates: moment.Moment[] = [
    moment().add(2, 'days'),
    moment().add(3, 'days'),
    moment().add(5, 'days'),
  ];

  isInvalidDate = (m: moment.Moment) => {
    return this.invalidDates.some((d) => d.isSame(m, 'day'));
  };

  setlocale: LocaleConfig = {
    applyLabel: 'Simpan',
    cancelLabel: 'Batal',
    separator: ' - ',
    clearLabel: 'Batal',
    format: 'DD MMM YYYY',
    displayFormat: 'DD MMM YYYY',
    daysOfWeek: ['Min', 'Sen', 'Sel', 'Rab', 'Kam', 'Jum', 'Sab'],
    monthNames: [
      'Januari',
      'Februari',
      'Maret',
      'April',
      'Mei',
      'Juni',
      'Juli',
      'Agustus',
      'September',
      'Oktober',
      'November',
      'Desember',
    ],
  };

  status: any = [
    {
      value: 'withdrawal',
      label: 'Penarikan Saldo',
      id: 'withdrawal',
      checked: false,
    },
    {
      value: 'order_received',
      label: 'Orderan COD (Diterima)',
      id: 'Received',
      checked: false,
    },
    {
      value: 'order_returned',
      label: 'Orderan COD (Retur)',
      id: 'Retur',
      checked: false,
    },
    {
      value: 'order_shipping',
      label: 'Orderan Non-COD (Ongkir)',
      id: 'Ongkir',
      checked: false,
    },
    {
      value: 'order_cancel',
      label: 'Orderan Non-COD (Dibatalkan)',
      id: 'Cancel',
      checked: false,
    },
    {
      value: 'insurance_fee',
      label: 'Asuransi',
      id: 'Insurance',
      checked: false,
    },
  ];

  isSemua: boolean = false;
  selectedStatuses: string[] = [];
  resi: string = '';

  constructor(
    private balanceService: BalanceService,
    private route: Router,
    private toastService: ToastService,
    public formatter: NgbDateParserFormatter,
    private modalService: NgbModal
  ) {
    this.keepCalendarOpeningWithRange = true;
  }
  ngOnInit(): void {
    this.getHistory();
    this.getBalance();

    this.searchSubject.pipe(debounceTime(300)).subscribe((value) => {
      this.filterHistory.airway_bill = value;
      this.filterResult.emit(this.filterHistory);
      this.getHistory();
    });
  }
  getBalance() {
    this.balanceService
      .balance()
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (res: ResponseCustomType<BalanceResponseType>) => {
          this.balance = res.data;
        },
        (error) => {}
      );
  }
  back() {
    this.route.navigate(['keuangan/saldo']);
  }

  onChangeSearch(value: any) {
    this.searchSubject.next(value);
  }

  getStatus(event: any, list: any) {
    const inputElement = event.target as HTMLInputElement;
    const isChecked = inputElement.checked;

    if (isChecked && !this.selectedStatuses.includes(list.value)) {
      this.selectedStatuses.push(list.value);
    } else if (!isChecked && this.selectedStatuses.includes(list.value)) {
      this.selectedStatuses = this.selectedStatuses.filter(
        (value) => value !== list.value
      );
    }

    if (this.selectedStatuses.length > 0) {
      this.filterHistory.transaction_type = this.selectedStatuses.join(',');
    } else {
      this.filterHistory.transaction_type =
        'withdrawal,order_shipping,order_returned,order_received,order_cancel,insurance_fee';
      this.selectedStatuses = [];
    }

    this.status = this.status.map((item: any) => {
      if (item.value === list.value) {
        return { ...item, checked: isChecked };
      }
      return item;
    });

    this.filterResult.emit(this.filterHistory);
    this.getHistory();
  }

  resetFilter() {
    this.filterHistory.start_date = moment()
      .subtract(6, 'days')
      .format('YYYY-MM-DD');
    this.filterHistory.end_date = moment().format('YYYY-MM-DD');
    (this.filterHistory.transaction_type =
      'withdrawal,order_shipping,order_returned,order_received,order_cancel,insurance_fee'),
      (this.selectedStatuses = []);

    this.filterResult.emit(this.filterHistory);
    this.getHistory();

    this.status.forEach((item: any) => {
      item.checked = false;
    });
  }
  openDatepicker() {
    this.pickerDirective.open();
  }
  onChangeDate(e: any) {
    const endDate: Dayjs = e.endDate;
    const startDate: Dayjs = e.startDate;

    this.filterHistory.start_date = startDate.format('YYYY-MM-DD');

    this.filterHistory.end_date = endDate.format('YYYY-MM-DD');

    this.filterResult.emit(this.filterHistory);
    this.getHistory();
  }

  salinResi(v: any): void {
    const noresi = v;
    navigator.clipboard
      .writeText(noresi)
      .then(() => {
        this.toastService.show(null, 'Nomor Resi berhasil disalin', 'success', {
          classname: 'bg-success',
          delay: 3000,
        });
      })
      .catch((e) => console.log(e));
  }

  getHistory(): void {
    this.balanceService
      .balanceHistory(this.filterHistory)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (res) => {
          this.HistoryList = res.data;
        },
        () => {}
      );
  }

  handleDownload(): void {
    const modal = this.modalService.open(ModalDlRincianSaldo, {
      backdrop: 'static',
      centered: true,
      size: 'lg',
    });

    modal.componentInstance.data = this.balance;
  }
  typePayment(v: any) {
    if (v === 'BANK TRANSFER') {
      return 'Orderan Non-COD';
    } else {
      return 'Orderan COD';
    }
  }
  typeTransaction(v: any) {
    if (v === 'withdrawal') {
      return 'Penarikan Saldo';
    }
    if (v === 'order_shipping') {
      return 'Ongkir';
    }
    if (v === 'order_returned') {
      return 'Retur';
    }
    if (v === 'order_received') {
      return 'Diterima';
    }
    if (v === 'order_cancel') {
      return 'Dibatalkan';
    }
    if (v === 'top_up') {
      return 'Top Up Saldo';
    }
    if (v === 'insurance_fee') {
      return 'Asuransi';
    } else {
      return '';
    }
  }
  classTransaction(v: any) {
    if (v === 'order_shipping') {
      return 'text-secondary';
    }
    if (v === 'order_returned') {
      return 'text-danger';
    }
    if (v === 'order_received') {
      return 'text-success';
    }
    if (v === 'order_cancel') {
      return 'text-secondary';
    }
    if (v === 'insurance_fee') {
      return 'text-danger';
    } else {
      return 'text-secondary';
    }
  }
  ngOnDestroy(): void {
    this.destroy$.unsubscribe();
  }
}
