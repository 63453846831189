import {
  Component,
  OnDestroy,
  OnInit,
  EventEmitter,
  Input,
  NgModule,
  Output,
  LOCALE_ID,
  ViewChild,
} from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { registerLocaleData } from '@angular/common';
import localeId from '@angular/common/locales/id';
import { wdListModel } from 'src/app/models/withdrawl-list.model';
import { BalanceService } from 'src/app/services/api/balance.service';
import { OrderService } from 'src/app/services/api/order.service';
import { BaseService } from 'src/app/services/base.service';
import * as moment from 'moment';
import 'moment/locale/id';
import {
  LocaleConfig,
  DaterangepickerDirective,
} from 'ngx-daterangepicker-material';
import { Dayjs } from 'dayjs';
import { Router } from '@angular/router';
import { KeuanganSaldoComponent } from '../../saldo/keuangan-saldo.component';

@Component({
  selector: 'app-table-riwayat-penarikan-saldo',
  templateUrl: './table-riwayat-penarikan-saldo.component.html',
  styleUrls: ['./table-riwayat-penarikan-saldo.component.scss'],
  providers: [{ provide: LOCALE_ID, useValue: 'id' }],
})
export class TableRiwayatPenarikanSaldoComponent {
  @Input() wdList: wdListModel[] = [];

  destroy$ = new Subject();

  bankNumber!: string;
  titlePage: string = 'Riwayat Penarikan Saldo';

  @Input() filterList: any = {
    start_date: moment().startOf('month').locale('id').format('YYYY-MM-DD'),
    end_date: moment().endOf('month').locale('id').format('YYYY-MM-DD'),
    type: '',
  };

  selected: any = {
    startDate: moment().startOf('month').locale('id'),
    endDate: moment().endOf('month').locale('id'),
  };

  ranges: any = {
    'Hari ini': [moment(), moment()],
    'Minggu ini': [moment().subtract(6, 'days'), moment()],
    'Bulan kemarin': [moment().subtract(29, 'days'), moment()],
    'Bulan ini': [moment().startOf('month'), moment().endOf('month')],
    Semua: [moment().startOf('year'), moment()],
  };

  invalidDates: moment.Moment[] = [
    moment().add(2, 'days'),
    moment().add(3, 'days'),
    moment().add(5, 'days'),
  ];

  isInvalidDate = (m: moment.Moment) => {
    return this.invalidDates.some((d) => d.isSame(m, 'day'));
  };

  @Output() filterResult = new EventEmitter();
  setlocale: LocaleConfig = {
    applyLabel: 'Simpan',
    cancelLabel: 'Kembali',
    separator: ' - ',
    clearLabel: 'Batal',
    format: 'DD MMM YYYY',
    displayFormat: 'DD MMM YYYY',
    daysOfWeek: ['Min', 'Sen', 'Sel', 'Rab', 'Kam', 'Jum', 'Sab'],
    monthNames: [
      'Januari',
      'Februari',
      'Maret',
      'April',
      'Mei',
      'Juni',
      'Juli',
      'Agustus',
      'September',
      'Oktober',
      'November',
      'Desember',
    ],
  };
  keepCalendarOpeningWithRange: boolean;

  constructor(
    private balanceService: BalanceService,
    private orderService: OrderService,
    public baseService: BaseService,
    private route: Router
  ) {
    this.keepCalendarOpeningWithRange = true;
  }

  ngOnInit(): void {
    this.getListWD();
  }

  onChangeDate(e: any) {
    const endDate: Dayjs = e.endDate;
    const startDate: Dayjs = e.startDate;
    // 2022-11-01
    this.filterList.start_date = startDate.format('YYYY-MM-DD');

    //2022-11-01
    this.filterList.end_date = endDate.format('YYYY-MM-DD');

    this.filterResult.emit(this.filterList);
    this.getListWD();
  }
  getListWD() {
    const currentRoute = this.route.url;
    if (currentRoute.endsWith('/keuangan/saldo')) {
      this.filterList.type = 'balance';
      this.filterResult.emit(this.filterList);
      this.titlePage = 'Riwayat Penarikan Saldo';
    } else {
      this.filterList.type = 'commission';
      this.filterResult.emit(this.filterList);
      this.titlePage = 'Riwayat Penarikan Komisi';
    }

    this.balanceService
      .withdrawlList(this.filterList)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (res) => {
          this.wdList = res.data;

          for (let i = 0; i < this.wdList.length; i++) {
            const numstring = this.wdList[i].bank_number;

            const cleanBankNumber = JSON.stringify(numstring);
            this.bankNumber = cleanBankNumber.replace(/"/g, '');
          }
          this.wdList.sort(
            (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()
          );
        },
        (error) => {}
      );
  }

  moveDetail(i: any) {
    const id = this.wdList[i].id;

    this.route.navigate(['keuangan/saldo/detail/', id]);
  }

  ngOnDestroy(): void {
    this.destroy$.unsubscribe();
  }
}
