import {
  Component,
  OnDestroy,
  OnInit,
  EventEmitter,
  Input,
  NgModule,
  Output,
  LOCALE_ID,
  ViewChild,
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject, takeUntil } from 'rxjs';
import { balanceModel } from 'src/app/models/balance.model';
import { MaintenanceComponent } from 'src/app/modules/shared/components/maintenance/maintenance.component';
import { BalanceService } from 'src/app/services/api/balance.service';
import { OrderService } from 'src/app/services/api/order.service';
import { BaseService } from 'src/app/services/base.service';
import { BalanceResponseType } from 'src/app/types/response/balance-response.type';
import { ResponseCustomType } from 'src/app/types/response/response.type';
import { ModalTarikSaldoComponent } from '../../dashboard/components/modal-tarik-saldo/modal-tarik-saldo.component';
import { Router } from '@angular/router';
import { UserService } from 'src/app/services/api/user.service';
import { ProfileResponseType } from 'src/app/types/response/profile-response.type';
import { BankService } from 'src/app/services/api/bank.service';
import { BankModel } from 'src/app/models/bank.model';
import { BuatRekeningComponent } from '../../dashboard/components/modal-buat-rekening/buat-rek.component';
import { BuatPinComponent } from '../../dashboard/components/modal-buat-pin/buat-pin.component';
import { wdListModel } from 'src/app/models/withdrawl-list.model';
import { BlokReqWDComponent } from 'src/app/modules/shared/components/modal-blocked-request-wd/blocked-req-wd.component';
import * as moment from 'moment';

@Component({
  selector: 'app-keuangan-saldo',
  templateUrl: './keuangan-saldo.component.html',
  styleUrls: ['./keuangan-saldo.component.scss'],
})
export class KeuanganSaldoComponent {
  @Input() balance!: balanceModel;
  @Input() wdList: wdListModel[] = [];
  destroy$ = new Subject();
  profile!: ProfileResponseType;
  pinStatus!: boolean;
  bankList: BankModel[] = [];

  constructor(
    private balanceService: BalanceService,
    private userService: UserService,
    public baseService: BaseService,
    private modalService: NgbModal,
    private route: Router,
    private bankService: BankService
  ) {}

  ngOnInit(): void {
    this.getBalance();
    this.getProfile();
    this.getBankList();
  }

  getProfile() {
    this.userService
      .profile()
      .pipe(takeUntil(this.destroy$))
      .subscribe((res: ResponseCustomType<ProfileResponseType>) => {
        this.profile = res.data;
        this.pinStatus = res.data.pin_status;
      });
  }

  getBankList(): void {
    this.bankService
      .bankList()
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (res) => {
          this.bankList = res.data;
        },
        (error) => {}
      );
  }
  getBalance() {
    this.balanceService
      .balance()
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (res: ResponseCustomType<BalanceResponseType>) => {
          this.balance = res.data;
        },
        (error) => {}
      );
  }

  handleTarikSaldo(e: any): void {
    const filterList: any = {
      start_date: '2019-01-01',
      end_date: moment().format('YYYY-MM-DD'),
      type: 'balance',
    };

    this.balanceService
      .withdrawlList(filterList)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (res) => {
          const dataList = res.data;

          let modalBlock = false;

          if (dataList !== null) {
            for (let i = 0; i < dataList.length; i++) {
              if (
                dataList[i].status === 'request' ||
                dataList[i].status === 'review'
              ) {
                modalBlock = true;
                break;
              }
            }

            if (modalBlock === true) {
              this.modalService.open(BlokReqWDComponent, {
                backdrop: 'static',
                centered: true,
              });
            } else {
              this.modalService.open(ModalTarikSaldoComponent, {
                backdrop: 'static',
                centered: true,
              });
            }
          } else {
            this.modalService.open(ModalTarikSaldoComponent, {
              backdrop: 'static',
              centered: true,
            });
          }
        },
        (error) => {}
      );
  }
  maintenance(e: any): void {
    this.modalService.open(MaintenanceComponent, {
      backdrop: 'static',
      centered: true,
    });
  }
  handleDetail(e: any): void {
    this.route.navigate(['/keuangan/saldo/detail']);
  }

  handleNoRek(e: any): void {
    this.modalService.open(BuatRekeningComponent, {
      backdrop: 'static',
      centered: true,
    });
  }
  handleNoPIN(e: any): void {
    this.modalService.open(BuatPinComponent, {
      backdrop: 'static',
      centered: true,
    });
  }

  ngOnDestroy(): void {
    this.destroy$.unsubscribe();
  }
}
