<nav
  class="navbar fixed-top navbar-light bg-white ps-3 pe-3 d-flex justify-content-between"
>
  <span class="navbar-brand mb-0 h1">
    <button class="btn" (click)="collapseClick()">
      <img src="assets/icons/black/menu.svg" />
    </button>
    <img class="ms-3 img-logo" height="30px" src="assets/logo.svg" alt="" />
  </span>
  <div class="flex-row d-flex align-items-center gap-3">
    <div class="title">Hallo {{ detailProfile.name }}</div>
    <div ngbDropdown display="dynamic">
      <img
        [src]="
          detailProfile.photo_profile_url
            ? detailProfile.photo_profile_url
            : 'https://cdn.pixabay.com/photo/2014/04/03/10/32/businessman-310819_960_720.png'
        "
        class="main-profile-img rounded-circle"
        style="width: 35px; cursor: pointer; height: 35px"
        alt="Avatar"
        id="dropdownBasic1"
        ngbDropdownToggle
      />
      <div
        ngbDropdownMenu
        aria-labelledby="dropdownBasic1"
        class="dropdown-menu dropdown-menu-end"
      >
        <div>
          <button (click)="logout()" ngbDropdownItem class="text-center">
            <img
              src="https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/logout.svg"
              alt=""
              width="28px"
            />
            <span class="mx-3">Logout</span>
          </button>
        </div>
      </div>
    </div>
    <img class="d-none" src="assets/icons/black/notification.svg" />
  </div>
</nav>
