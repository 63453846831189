import {
  Component,
  OnDestroy,
  OnInit,
  EventEmitter,
  Input,
  NgModule,
  Output,
  LOCALE_ID,
  ViewChild,
} from '@angular/core';
import { Router, RouterModule, Routes } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { FormsModule } from '@angular/forms';
import { DataOrderModel, OrderModel } from 'src/app/models/data-order.model';
import { OrderService } from 'src/app/services/api/order.service';

@Component({
  selector: 'app-table-data-order',
  templateUrl: './table-data-order.component.html',
  styleUrls: ['./table-data-order.component.scss'],
})
export class TableDataOrderComponent {
  @Input() dataOrder: OrderModel[] = [];
  destroy$ = new Subject();
  public isCollapsed = false;

  page: number = 1;
  perPage: number = 20;

  constructor(private orderService: OrderService, private route: Router) {}
  ngOnInit(): void {}

  moveDetail(i: any) {
    const id = this.dataOrder[i].id;

    this.route.navigate(['data-order/detail-order', id]);
  }
}
