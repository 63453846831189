<div class="d-flex flex-column gap-3">
  <h3>Dashboard</h3>
  <div class="row">
    <div class="col-md-4 col mt-1">
      <div class="card shadow card-top">
        <div class="p-3">
          <div class="d-flex align-items-center gap-1">
            <div class="card-title d-flex align-self-center">Saldo</div>
            <div
              class="d-flex align-self-center"
              placement="top left"
              ngbPopover="Saldo kamu saat ini"
              triggers="mouseenter:mouseleave"
            >
              <i class="icon-info-circle align-self-center"></i>
            </div>
          </div>
          <div class="nominal">
            <b>{{ balance.balance | currency : "IDR" : true : "1.0" }}</b>
          </div>
        </div>
        <div class="bg-footer-card p-3 d-flex flex-row justify-content-center">
          <button
            *ngIf="pinStatus === true && bankList !== null"
            class="d-flex px-3 flex-row gap-2 align-items-center justify-content-center"
            style="border: none; background-color: transparent"
            (click)="handleTarikSaldo($event, 'balance')"
          >
            <div><i class="icon icon-receive-square"></i></div>
            <div class="d-flex text-card-button">Tarik Saldo</div>
          </button>
          <button
            *ngIf="pinStatus === true && bankList === null"
            class="d-flex px-3 flex-row gap-2 align-items-center justify-content-center"
            style="border: none; background-color: transparent"
            (click)="handleNoRek($event)"
          >
            <div><i class="icon icon-receive-square"></i></div>
            <div class="d-flex text-card-button">Tarik Saldo</div>
          </button>
          <button
            *ngIf="pinStatus === false && bankList !== null"
            class="d-flex px-3 flex-row gap-2 align-items-center justify-content-center"
            style="border: none; background-color: transparent"
            (click)="handleNoPIN($event)"
          >
            <div><i class="icon icon-receive-square"></i></div>
            <div class="d-flex text-card-button">Tarik Saldo</div>
          </button>
          <button
            *ngIf="pinStatus === false && bankList === null"
            class="d-flex px-3 flex-row gap-2 align-items-center justify-content-center"
            style="border: none; background-color: transparent"
            (click)="handleNoPIN($event)"
          >
            <div><i class="icon icon-receive-square"></i></div>
            <div class="d-flex text-card-button">Tarik Saldo</div>
          </button>
          <a [routerLink]="['/keuangan/saldo/detail']">
            <button
              class="d-flex px-3 flex-row gap-2 align-items-center justify-content-center"
              style="border: none; background-color: transparent"
            >
              <div><i class="icon icon-document-text"></i></div>
              <div class="d-flex text-card-button">Detail</div>
            </button>
          </a>
        </div>
      </div>
    </div>
    <div class="col-md-4 col mt-1">
      <div class="card shadow card-top">
        <div class="p-3">
          <div class="d-flex align-items-center gap-1">
            <div class="card-title d-flex align-self-center">
              Potensial Income
            </div>
            <div
              class="d-flex align-self-center"
              placement="top right"
              ngbPopover="Potensial Income merupakan saldo yang akan kamu terima ketika orderan yang statusnya dikirim telah berubah menjadi diterima."
              triggers="mouseenter:mouseleave"
            >
              <i class="icon-info-circle align-self-center"></i>
            </div>
          </div>
          <div class="nominal">
            <b>{{
              possibleIncome.possible_income | currency : "IDR" : true : "1.0"
            }}</b>
          </div>
        </div>
        <div class="footer-card-text p-3 d-flex flex-column">
          <div>
            Dari nilai tersebut, <b class="text-primary">Rp 0,-</b> nya perlu
            dimonitor
          </div>
          <div></div>
        </div>
      </div>
    </div>
    <div class="col-md-4 col mt-1">
      <div class="card shadow card-top">
        <div class="p-3">
          <div class="d-flex align-items-center gap-1">
            <div class="card-title d-flex align-self-center">Komisi</div>
            <div
              class="d-flex align-self-center"
              placement="top right"
              ngbPopover="Komisi dihitung 1%  dari total ongkos kirim"
              triggers="mouseenter:mouseleave"
            >
              <i class="icon-info-circle align-self-center"></i>
            </div>
          </div>
          <div class="nominal">
            <b>{{ balance.commission | currency : "IDR" : true : "1.0" }}</b>
          </div>
        </div>
        <div class="bg-footer-card p-3 d-flex flex-row justify-content-center">
          <button
            *ngIf="pinStatus === true && bankList !== null"
            class="d-flex px-3 flex-row gap-2 align-items-center justify-content-center"
            style="border: none; background-color: transparent"
            (click)="handleTarikKomisi($event, 'commission')"
          >
            <div><i class="icon icon-receive-square"></i></div>
            <div class="d-flex text-card-button">Tarik Saldo</div>
          </button>
          <button
            *ngIf="pinStatus === true && bankList === null"
            class="d-flex px-3 flex-row gap-2 align-items-center justify-content-center"
            style="border: none; background-color: transparent"
            (click)="handleNoRek($event)"
          >
            <div><i class="icon icon-receive-square"></i></div>
            <div class="d-flex text-card-button">Tarik Saldo</div>
          </button>
          <button
            *ngIf="pinStatus === false && bankList !== null"
            class="d-flex px-3 flex-row gap-2 align-items-center justify-content-center"
            style="border: none; background-color: transparent"
            (click)="handleNoPIN($event)"
          >
            <div><i class="icon icon-receive-square"></i></div>
            <div class="d-flex text-card-button">Tarik Saldo</div>
          </button>
          <button
            *ngIf="pinStatus === false && bankList === null"
            class="d-flex px-3 flex-row gap-2 align-items-center justify-content-center"
            style="border: none; background-color: transparent"
            (click)="handleNoPIN($event)"
          >
            <div><i class="icon icon-receive-square"></i></div>
            <div class="d-flex text-card-button">Tarik Saldo</div>
          </button>
          <a [routerLink]="['/keuangan/komisi/detail']">
            <button
              class="d-flex px-3 flex-row gap-2 align-items-center justify-content-center"
              style="border: none; background-color: transparent"
            >
              <div><i class="icon icon-document-text"></i></div>
              <div class="d-flex text-card-button">Detail</div>
            </button>
          </a>
        </div>
      </div>
    </div>
  </div>
  <div class="card shadow">
    <div class="d-flex flex-column">
      <div
        class="d-flex flex-row justify-content-between align-items-center p-3"
      >
        <div class="header">Dashboard Order</div>
        <div>
          <form class="row row-cols-sm-auto">
            <div class="col-12">
              <div
                class="form-control d-flex flex-row"
                (click)="openDatepicker()"
              >
                <input
                  type="text"
                  class="btn p-0 w-100"
                  ngxDaterangepickerMd
                  [(ngModel)]="selected"
                  [ngModelOptions]="{ standalone: true }"
                  [showCustomRangeLabel]="false"
                  [keepCalendarOpeningWithRange]="true"
                  [alwaysShowCalendars]="true"
                  [ranges]="ranges"
                  [linkedCalendars]="true"
                  [isInvalidDate]="isInvalidDate"
                  [showCancel]="true"
                  opens="left"
                  drops="down"
                  [locale]="setlocale"
                  (change)="onChangeDate($event)"
                  style="border: none; font-size: 14px"
                  #datePickerInput
                />
                <button
                  class="btn ngx-daterangepicker-action"
                  (click)="openDatepicker()"
                >
                  <img
                    src="https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/calendar.svg"
                    alt=""
                  />
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div class="divider"></div>
      <div class="d-flex mb-5 flex-column flex-md-row card-ov">
        <div class="card-dashboard">
          <div class="total-shipment-card">
            <div class="p-3 d-flex flex-column align-items-start gap-2">
              <div class="label">Total Shipment</div>
              <div class="mt-1 text-white txt">Total Pengiriman</div>
              <div class="text-white text-nominal">
                <b>{{ accumulationOrder?.total_order || 0 }}</b>
              </div>
              <div class="mt-1 text-white txt-nom">Total Nominal COD</div>
              <div class="text-white text-nominal">
                <b>{{
                  (accumulationOrder?.total_cod
                    | currency : "IDR" : true : "1.0") || 0
                }}</b>
              </div>
            </div>
          </div>
        </div>
        <div class="card-dashboard">
          <div class="delivered-card">
            <div class="p-3 d-flex flex-column align-items-start gap-2">
              <div class="label">Delivered</div>
              <div class="text-white percent-deliver">
                {{ accumulationOrder?.delivered.percentage | number }}%
              </div>
              <div class="mt-1 text-white txt">Paket Terkirim</div>
              <div class="text-white text-nominal">
                <b>{{ accumulationOrder?.delivered.total_order || 0 }}</b>
              </div>
              <div class="mt-1 text-white txt-nom">Total Nominal COD</div>
              <div class="text-white text-nominal">
                <b>{{
                  (accumulationOrder?.delivered.total_cod
                    | currency : "IDR" : true : "1.0") || 0
                }}</b>
              </div>
            </div>
          </div>
        </div>
        <div class="card-dashboard">
          <div class="returned-card">
            <div class="p-3 d-flex flex-column align-items-start gap-2">
              <div class="label">Returned</div>
              <div class="text-white percent-deliver">
                {{ accumulationOrder?.returned.percentage | number }}%
              </div>
              <div class="mt-1 text-white txt">Paket Retur</div>
              <div class="text-white text-nominal">
                <b>{{ accumulationOrder?.returned.total_order || 0 }}</b>
              </div>
              <div class="mt-1 text-white txt-nom">Total Nominal COD</div>
              <div class="text-white text-nominal">
                <b>{{
                  (accumulationOrder?.returned.total_cod
                    | currency : "IDR" : true : "1.0") || 0
                }}</b>
              </div>
            </div>
          </div>
        </div>
        <div class="card-dashboard">
          <div class="process-returned-card">
            <div class="p-3 d-flex flex-column align-items-start gap-2">
              <div class="label">On Process Return</div>
              <div class="text-white percent-deliver">
                {{ accumulationOrder?.return_process.percentage | number }}%
              </div>
              <div class="mt-1 text-white txt">Paket Proses Retur</div>
              <div class="text-white text-nominal">
                <b>{{ accumulationOrder?.return_process.total_order || 0 }}</b>
              </div>
              <div class="mt-1 text-white txt-nom">Total Nominal COD</div>
              <div class="text-white text-nominal">
                <b>{{
                  accumulationOrder?.return_process.total_cod
                    | currency : "IDR" : true : "1.0"
                }}</b>
              </div>
            </div>
          </div>
        </div>
        <div class="card-dashboard">
          <div class="process-delivery-card">
            <div class="p-3 d-flex flex-column align-items-start gap-2">
              <div class="label">On Process Delivery</div>
              <div class="text-white percent-deliver">
                {{ accumulationOrder?.delivery.percentage | number }}%
              </div>
              <div class="mt-1 text-white txt">Paket Dalam Pengiriman</div>
              <div class="text-white text-nominal">
                <b>{{ accumulationOrder?.delivery.total_order || 0 }}</b>
              </div>
              <div class="mt-1 text-white txt-nom">Total Nominal COD</div>
              <div class="text-white text-nominal">
                <b>{{
                  (accumulationOrder?.delivery.total_cod
                    | currency : "IDR" : true : "1.0") || 0
                }}</b>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
